@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family:'Montserrat', sans-serif !important;
  background: #FEF9EC !important;
}
.ant-layout{
  background:transparent !important;
}
.menu-drawer .ant-drawer-header-no-title{
  height: 50px !important;
  background: transparent !important;
}
.menu-drawer .ant-drawer-content{
  background-color: #4b5f64e6 !important;
}
.vjs-logo img{
  width:45px;
}
.menu-drawer .ant-drawer-close{
  color:#ffffff !important;
}
.menu-drawer .ant-drawer-body{
  padding: 10px 20px !important;
  padding-top:60px !important;
}
.menu-drawer .ant-drawer-body .ant-menu{
  background: transparent;
  color: #ffffff;
  border: 0px;
}
.menu-drawer .ant-drawer-body .ant-menu .ant-menu-item.ant-menu-item-active, .ant-drawer-body .ant-menu .ant-menu-item.ant-menu-item-selected{
  background: #ec6a40 !important;
}
.ant-btn-primary{
  border-color: #ec6a40 !important;
  background: #ec6a40 !important;
}
.ant-btn:hover, .ant-btn:focus{
  border-color: #ec6a40 !important;
  color: #ec6a40 !important;
  background: #fff !important;
}

.ant-btn.text-link:hover, .ant-btn.text-link:focus{
  border-color: transparent !important;
  border-bottom-color: #ec6a40 !important;
  color: #ec6a40 !important;
  background: transparent !important;
}

.ant-btn-primary:hover, .ant-btn-primary:focus{
  border-color: #ec6a40 !important;
  color: #ec6a40 !important;
  background: #fff !important;
}

.menu-drawer .ant-drawer-body .ant-menu .ant-menu-item a{
  color:#ffffff;
  font-size:16px;
}

.menu-drawer .ant-drawer-body .ant-menu .ant-menu-item{
  padding:0px 10px;
}
.ant-message{
  z-index: 1000000000000;
}

.ant-message-notice-content{
  border-radius:30px !important;
}

.login-drawer .ant-drawer-header-no-title{
  background: transparent !important;
}
.login-drawer .ant-drawer-content{
  background-color: #4b5f64e6 !important;
}

.videoinfo-popup .ant-modal-header{
  display: none;
}

.videoinfo-popup .ant-modal-body{
    padding: 0px;
}

.login-drawer .ant-drawer-close{
  color:#ffffff !important;
  top: 30px !important;
  right: 30px !important;
  font-size: 20px !important;
}
.login-drawer .ant-drawer-body{
  padding: 10px 20px !important;
  padding-top:60px !important;
  display:flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.login-drawer .ant-drawer-body .login-container{
  padding:20px;
  margin:auto;
  max-width: 320px;
  width: 100%;
  box-sizing: border-box;
}

.custom-page-header .ant-page-header-heading-left{
  width:100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  
}
@media (max-width: 768px) {
  .custom-page-header .ant-page-header-heading-left{
    justify-content: flex-start;
  }
}
.header-heading-center .ant-page-header-heading-left{
  flex-direction: column;
}
.custom-page-header .ant-page-header-back{
    background-color: #ec6a40;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 30px;
    
}
.custom-page-header .ant-page-header-back svg{
  color: #ffffff;
}
.custom-page-header .ant-page-header-heading-left .ant-page-header-heading-title, button{
  font-size:26px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;
  color:#2C4349;
}
.ant-modal-content{
  border-radius: 20px !important;
  background: #FEF9EC !important;
  overflow: hidden;
}

.custom-page-header .ant-page-header-heading-left .ant-page-header-heading-sub-title{
  font-size:16px;
  font-weight: 400;
  color:#2C4349;
}

.spin{
  position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000050;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 31px;
}
.spin .ant-spin-dot{
  font-size:40px;
}
.spin .ant-spin-dot-item{
  width: 20px;
  height: 20px;
  background-color: #a2492c;
}

.app-video .header{
  display: none !important;
}
.app-video .ant-layout-content.site-layout{
  margin:0 !important;
}
.app-video .footer{
  display: none !important;
}

.ant-modal-header{
  background: transparent !important;
}
.ant-modal-content{
}


.items-carousel-arrow{
  width: 40px;
  height: 40px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: #ee7d58;
  margin-top: -40px;
  padding: 0;
  border: 2px solid #e96815;
  box-shadow: 0px 0px 2px #6b6b6b;
  opacity: 0.6;
}
.items-carousel-arrow:hover{
  opacity: 1;
}
.items-carousel-arrow.left{
  margin-left: -40px;
}
.items-carousel-arrow.right{
  margin-right: -40px;
}

@media (max-width: 768px) {
  .custom-page-header{
    padding: 16px 0px !important;
    
  }
  .items-carousel-arrow{
    display: none;
  }
  .custom-page-header .ant-page-header-heading-title{
    margin:0;
    overflow: unset;
    font-size:24px !important;
    white-space: unset;
    text-align: center;
    text-overflow: unset;
  }


}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
 #root.app-video{
    /* -ms-transform: rotate(-90deg); 
    -webkit-transform: rotate(-90deg); 
    transform: rotate(-90deg); */
    /* overflow: scroll; */
    border:2px solid #f00;
  }
}



.cookie-container{
  position: fixed;
  display: flex;
  visibility: visible;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: rgba(0,0,0,0.1);
  justify-content: flex-start;
  align-items: flex-end;
  top: 0;
  left: 0;
  right: 0;
  z-index:10;
  bottom: 0;
}
.cookie-card {
  flex:1;
  padding: 1rem 2rem;
  /* font-size: 1rem; */
  /* flex-wrap: wrap; */
  background: #fff;
  box-shadow: 0px -1px 6px #2a2a2a;
  width: 100%;
  flex-direction: column;
  height: auto;
  text-align: center;
}
.cookie-card h4{
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  line-height: 26px;
}

.cookie-container.hide {
  opacity: 0;
  visibility: hidden;
}

.cookie-card a{
  color: #ec6a40;
  font-weight: 600;
}

.cookie-card .cookie-text {
  flex: 8 768px;
}

.cookie-card .agree {
  flex: 1 150px;
  text-align: center;
}

.cookie-card .agree button {
  padding: 0.4rem 1.2rem;
  cursor: pointer;
  border-radius: 20px;
  font-size: 1rem;
  background: rgb(236, 106, 64);
  border-color: rgb(236, 106, 64);
  color: #fff;
}

.cookie-card .agree button:hover {
}


.progress-bar{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-slider{
  width: 100%;
}
.progress-bar .timer{
  background: #fff;
  font-size: 9px;
  font-weight: 600;
  padding: 1px 4px;
  background: #ec6a40 !important;
  border-radius: 100px;
}

.progress-bar .ant-slider .ant-slider-handle{
  background: #ec6a40 !important;
  border-color: #ec6a40 !important;
}
.progress-bar .ant-slider .ant-slider-track{
  background: #ec6a40 !important;
}

.video-js .vjs-big-play-button{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.ant-rate{
  color: #ec6a40 !important;
}
.ant-rate-star.ant-rate-star-zero .ant-rate-star-first, .ant-rate-star.ant-rate-star-zero .ant-rate-star-second{
  color: #d8d8d8;
}

.ant-modal-footer .ant-btn{
  border-radius: 30px;
}